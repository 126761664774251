  .submit-button {
    background-color: #3444DA;
    color: white !important;
    font-size: 16px;
    font-weight: bold;
    padding: 10px 40px;
    border-radius: 30px;
    cursor: pointer;
    
    width: 150px;
    display: flex;
    justify-content: space-between;
    text-decoration: none;
  }

  .selected{
    color: none !important;
  }

  .success p{
    font-family: Product, sans-serif;
    font-size: 20px;
    font-weight: 600;
  }

  .loading {
  position: relative;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3444DA;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  animation: spin 2s linear infinite;
  position: absolute;
  top: 30%;
  left: 40%;
  padding: 10px 20px;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (min-width: 768px) {
  .ok-button {
    font-size: 16px; /* Increase font size for tablets */
  }
}

/* Phones and smaller screens */
@media (max-width: 480px) {
  .ok-button {
    font-size: 14px; /* Decrease font size for phones */
    padding: 8px 16px; /* Adjust padding accordingly */
  }
}