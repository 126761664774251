.banner {
  color: white;
}

.banner p {
  /* font-family: 'Outfit', sans-serif; */
  font-size: 16px;
}

.input-container {
  position: relative;
  padding-left: 0;
  margin: 0 10px
}

.custom-input {
  border: none;
  border-bottom: 1px solid white;
  background: transparent;
  width: 100%;
  outline: none;
  /* font-family: 'Outfit', sans-serif; */
  font-family: Product;
  font-size: 24px;
  color: white;
  padding: 10px;
  transition: border-width 0.3s;
  padding-left: 0;
}
.custom-input::placeholder {
  font-family: Product;
}
.custom-input:focus {
  /* border-width: 3px; */
  font-family: Product;
  border: 0px;
  outline: none;
  border-bottom: 1px;
  /* text-underline-offset: 1px; */
}

.custom-input:active {
  /* border-width: 3px; */
  border: 0px;
  font-family: Product;
  outline: none;
}
/* Media queries for responsiveness */

/* Tablets and larger screens (landscape mode) */
@media (min-width: 768px) {
  .input-container {
    max-width: 100%; /* Set a maximum width for the input container */
    margin: 0 auto; /* Center the container horizontally */
  }
  .banner p {
    font-size: 18px; /* Increase font size for tablets */
  }
  .custom-input {
    font-size: 24px;
  }
}

/* Phones and smaller screens */
@media (max-width: 480px) {
  .banner p {
    font-size: 14px; /* Decrease font size for phones */
  }
  .custom-input {
    font-size: 16px; /* Decrease font size for phones */
  }
}