.selectionContainer2 {
  border: 2px #ffff solid;
  border-radius: 30px;
  display: flex;
  flex-direction: "row";
  justify-content: space-between;
  align-items: center;
  padding: 0px 5px;
  margin: 0;
  /* background-color: #332a4c; */
  cursor: pointer;
  font-family: Product, sans-serif;
  width: 180px;
}

.choiceMarker2 {
  padding-right: 5px;
  color: white;
  margin: 0;
}

.selectionContainer2:hover {
  border-radius: 30px;
  display: flex;
  flex-direction: "row";
  justify-content: space-between;
  align-items: center;
  padding: 0px 5px;
  margin: 0;
  background-color: #524b68;
}

.choice2 {
  border: 2px #ffff solid;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  font-weight: 600;
  background-color: #1c1238;
  margin: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.choiced2 {
  border: 2px #ffff solid;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  font-weight: 600;
  background-color: #ffff;
  margin: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1c1238;
}

.choiceTitle2 {
  font-weight: 600;
  font-size: 20px;
  margin: 6px;
}

@media (min-width: 1201px) {
  .selectionContainer2 {
    width: 190px;
  }
  .choiceTitle2 {
    font-weight: 600;
    font-size: 20px;
    margin: 0px;
  }
  .choiced2,
  .choice2 {
    height: 20px;
    width: 20px;
    margin: 6px;
    font-size: 12px;
    padding: 2px;
  }
}

@media (max-width: 1200px) {
  .selectionContainer2 {
    width: 150px;
  }
  .choiceTitle2 {
    font-weight: 600;
    font-size: 18px;
    margin: 0px;
  }
  .choiced2,
  .choice2 {
    height: 20px;
    width: 20px;
    margin: 6px;
    font-size: 10px;
    padding: 2px;
  }
}

@media (max-width: 992px) {
  .selectionContainer2 {
    width: 130px;
  }
  .choiceTitle2 {
    font-weight: 600;
    font-size: 14px;
    margin: 0px;
  }
  .choiced2,
  .choice2 {
    height: 14px;
    width: 14px;
    margin: 6px;
    font-size: 10px;
    padding: 2px;
  }
}

@media (max-width: 768px) {
  .selectionContainer2 {
    width: 140px;
  }
  .choiceTitle2 {
    font-weight: 600;
    font-size: 14px;
    margin: 0px;
  }
  .choiced2,
  .choice2 {
    height: 14px;
    width: 14px;
    margin: 6px;
    font-size: 10px;
    padding: 2px;
  }
}

@media (max-width: 576px) {
  .selectionContainer2 {
    width: 100px;
  }
  .choiceTitle2 {
    font-weight: 600;
    font-size: 10px;
    margin: 0px;
  }
  .choiced2,
  .choice2 {
    height: 10px;
    width: 10px;
    margin: 6px;
    font-size: 8px;
    padding: 2px;
  }
}
/* Phones and smaller screens */
@media (max-width: 260px) {
  .selectionContainer2,
  .selectionContainer2:hover {
    font-size: 12px; /* Decrease font size for phones */
    width: 100px;
  }
  .choiceTitle2 {
    font-weight: 600;
    font-size: 10px;
    margin: 6px;
  }

  .choiced2,
  .choice2 {
    height: 10px;
    width: 10px;
    margin: 6px;
    font-size: 8px;
    padding: 2px;
  }
}

@media (max-width: 260px) {
  .selectionContainer2,
  .selectionContainer2:hover {
    font-size: 12px; /* Decrease font size for phones */
    width: 80px;
  }
  .choiceTitle2 {
    font-weight: 600;
    font-size: 8px;
    margin: 6px;
  }

  .choiced2,
  .choice2 {
    height: 8px;
    width: 8px;
    margin: 6px;
    font-size: 6px;
    padding: 2px;
  }
}
