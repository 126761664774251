.headerContainer {
    display: flex;
    align-items: flex-start;
    padding: 0;
    margin: 0;
  }
  
  .headerContainer span {
    /* font-family: 'Outfit', sans-serif; */
  }
  
  .header {
    font-size: 36px;
    line-height: 44px;
    font-weight: 400;
    padding: 0;
    margin-bottom: 20px;
    margin-top: 0;
    font-family: 'Outfit', sans-serif;
  }
    
  @media (min-width: 992px) {
    .header {
      font-size: 30px; /* Increase font size for tablets */
      line-height: 30px; /* Adjust line height accordingly */
      margin-bottom: 30px; /* Increase margin for tablets */
    }
  }
  /* Tablets and larger screens (landscape mode) */

  
  /* Phones and smaller screens */
  @media (max-width: 480px) {
    .header {
      font-size: 24px; /* Decrease font size for phones */
      line-height: 32px; /* Adjust line height accordingly */
      margin-bottom: 10px; /* Decrease margin for phones */
    }
  }
  
  /* You can add more media queries and adjust the styles as needed for other screen sizes */
  