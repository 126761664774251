.ok-button {
  /* background-color: #3444DA; */
  color: white !important;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 30px;
  border-radius: 10px;
  cursor: pointer;
  width: 108px;
  display: flex;
  justify-content: space-around;
  border: 1px solid white;
  text-decoration: none;
  font-family: Product;
}

.selected {
  color: none !important;
}

/* Media queries for responsiveness */

/* Tablets and larger screens (landscape mode) */
@media (min-width: 768px) {
  .ok-button {
    font-size: 16px; /* Increase font size for tablets */
  }
}

/* Phones and smaller screens */
@media (max-width: 480px) {
  .ok-button {
    font-size: 14px; /* Decrease font size for phones */
    padding: 8px 9px; /* Adjust padding accordingly */
  }
}
