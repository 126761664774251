

.main {
    width: 80%;
    min-height: 80%;
    position: absolute;
    z-index: -1;
    display: flex;
    justify-content: center;
    pointer-events: none;
  }
  
  .main:before {
    background: radial-gradient(circle,
        rgba(24, 13, 3, 0) 0,
        var(--background) 100%);
    position: absolute;
    content: "";
    z-index: 2;
    width: 100%;
    height: 55%;
    top: 0;
  }
  
  .main:after {
    content: "";
    /* background-image: url("https://raw.githubusercontent.com/loglib/loglib/main/apps/www/public/assets/grid.svg"); */
    z-index: 1;
   
    position: absolute;
    width: 100%;
    height: 55%;
    top: 0;
    opacity: 0.3;
    filter: invert(1);
  }
  
  .content {
    height: fit-content;
    z-index: 3;
    width: 100%;
    max-width: 50%;
    background-image: radial-gradient(at 27% 37%,
        rgb(126,34,206)0px,
        transparent 0%),
      radial-gradient(at 97% 21%, rgba(126,34,206 ,0.608) 0px, transparent 50%),
      radial-gradient(at 52% 99%, rgb(7126,34,206 ) 0px, transparent 50%),
      radial-gradient(at 10% 29%, rgba(126,34,206 , 0.333 ) 0px, transparent 50%),
      radial-gradient(at 97% 96%, rgba(126,34,206 , 0.77) 0px, transparent 50%),
      radial-gradient(at 33% 50%, rgba(10, 68, 202, 0.2) 0px, transparent 50%),
      radial-gradient(at 79% 53%, rgba(61, 3, 21 , 0.6) 0px, transparent 50%);
    position: absolute;
    content: "";
    width: 100%;
    height: 55%;
    filter: blur(100px) saturate(150%);
    opacity: 0.33;
  }
  
  .dark .main:before {
    background: radial-gradient(circle, rgba(2, 0, 36, 0) 0, #0f0215 100%);
  }