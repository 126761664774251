.selectionContainer{
    border: 1px #ffff solid;
    font-family: Product;
    border-radius: 30px;
    display:flex;
    flex-direction: "row";
    justify-content: space-between;
    align-items: center;
    padding: 0px 5px;
    margin: 0;
    /* background-color: #332A4C; */
    
    cursor: pointer;
    /* font-family: 'Outfit', sans-serif; */
}


.choiceMarker{
    padding-right: 5px;
    margin: 0;
}

.selectionContainer:hover{
    border-radius: 30px;
    display:flex;
    flex-direction: "row";
    justify-content: space-between;
    align-items: center;
    padding: 0px 5px;
    margin: 0;
    background-color: #524B68;
}

.choice{
    border: 2px #ffff solid;
    border-radius: 50%;
    height:25px;
    width: 25px;
    font-weight: 600;
    background-color: #1C1238;
    margin: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.choiced {
    border: 2px #ffff solid;
    border-radius: 50%;
    height:25px;
    width: 25px;
    font-weight: 600;
    background-color: #ffff;
    margin: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1C1238;
}

.choiceTitle{
    font-weight: 600;
    font-size: 20px;
    margin: 6px;
}

@media (min-width: 768px) {
    .selectionContainer,
    .selectionContainer:hover {
      font-size: 14px; /* Increase font size for tablets */
    }
    .choiceTitle {
      font-size: 18px; /* Increase font size for tablets */
    }
    .choiceMarker {
      padding-right: 10px; /* Adjust padding for tablets */
    }
  }
  
  /* Phones and smaller screens */
  @media (max-width: 480px) {
    .selectionContainer,
    .selectionContainer:hover {
      font-size: 12px; /* Decrease font size for phones */
    }
    .choiceTitle {
      font-size: 14px; /* Decrease font size for phones */
    }
    .choiceMarker {
      padding-right: 2px; /* Adjust padding for phones */
    }
  }