@tailwind base;
@tailwind components;
@tailwind utilities;
/* 
@import url('https://fonts.googleapis.com/css2?family=Outfit&display=swap'); */

@font-face {
  font-family: 'Cals';
  src: local('Cals') , url(./fonts/CalSans-SemiBold.ttf) format('opentype');
}

.pageContainer{
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 52%;
}
.Container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Cals;

  
}

.screenContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.calendly{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center
}

@media(max-width: 610px) {

  .calendly{
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    height:50vh;
    align-items: center

  }
}

@media (max-width: 882px) {
  .pageContainer{
    width: 65%
  }
}

@media (max-width: 768px) {
  .pageContainer{
    width: 74%
  }
}

/* Phones and smaller screens */
@media (max-width: 500px) {
  .pageContainer{
    width: 85%
  }
  .screenContainer{
    overflow-y: auto;
  }
}

/* Phones and smaller screens */
@media (max-width: 480px) {
  .pageContainer{
    width: 100%
  }
  .screenContainer{
    overflow-y: auto;
  }
}
